const useViewGlobalBackground = () => {
    function show(value: boolean): Promise<any> {
        return new Promise((resolve) => {
            const element = document.getElementById('global-background');
            value &&
                element &&
                (element.style.visibility = 'visible') &&
                (element.style.background = 'rgba(0,0,0,0.2)');
            !value &&
                element &&
                (element.style.visibility = 'hidden') &&
                (element.style.background = 'rgba(0,0,0,0)');
            setTimeout(() => resolve(true), 300);
        });
        // element.className = 'hidden'
    }

    return show;
};

export default useViewGlobalBackground;
