import { useState } from 'react';
import InputText, { InputTextInterface } from '../InputText';

const InputPassword = (props: InputTextInterface) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    return (
        <div className={'position-relative'}>
            <InputText
                {...props}
                styles={{
                    paddingRight: '35px'
                }}
                id={props.id}
                type={showPassword ? 'text' : 'password'}
            />
            <div
                onClick={() => setShowPassword(!showPassword)}
                className={'position-absolute cursor-pointer'}
                style={{
                    top: '10px',
                    right: '5px'
                }}
            >
                {showPassword ? (
                    <img src="/client/src/assets/img/eye_open.svg" alt="" />
                ) : (
                    <img src="/client/src/assets/img/eye_close.svg" alt="" />
                )}
            </div>
        </div>
    );
};

export default InputPassword;
