import {ToastOptions} from "react-toastify/dist/types";
import {Slide} from "react-toastify";
import Close from "../components/Icons/Close";

export const ROUTES = {
    SIGN_IN: 'signin',
    DASHBOARD: 'dashboard',
}

export const DEFAULT_OPTIONS_TOAST: ToastOptions = {
    hideProgressBar: true,
    autoClose: 2000,
    theme: 'light',
    pauseOnHover: true,
    transition: Slide,
    closeButton: (props) => Close()
}

export const COMMERCIAL_IDS = ['155', '156', '157', '158', '159', '160', '161', '016', '0116']
