import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import uuid from 'react-uuid';

const Index = ({
    hintMessage,
    styles
}: {
    styles?: CSSProperties;
    hintMessage: string;
}) => {
    const refErrorText = useRef();

    const idHintContainer = useRef(uuid()).current;

    const [idInterval, setIdInterval] = useState<number>(undefined);

    useEffect(() => {
        const timer = setInterval(() => {
            // @ts-ignore
            const height = refErrorText.current?.offsetHeight;
            if (height) {
                document.getElementById(idHintContainer)?.style &&
                    (document.getElementById(idHintContainer).style.height = `${
                        height + 5
                    }px`);
            } else {
                document.getElementById(idHintContainer)?.style &&
                    (document.getElementById(idHintContainer).style.height =
                        `0px`);
            }
        }, 100);

        setIdInterval(+timer);

        return () => {
            clearInterval(idInterval);
        };
    }, []);

    return (
        <div
            className={'hint-input'}
            id={idHintContainer}
            style={{
                overflow: 'hidden',
                height: 0
            }}
        >
            <Form.Text
                ref={refErrorText}
                style={{
                    ...styles,
                    fontSize: 12,
                    color: 'var(--primary-grey)',
                    lineHeight: '15px'
                }}
                className={'hint-input'}
            >
                {hintMessage}
            </Form.Text>
        </div>
    );
};

export default Index;
