import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import uuid from 'react-uuid';

const Index = ({
    errorMessage,
    className,
    styles
}: {
    styles?: CSSProperties;
    className?: string;
    errorMessage: string;
}) => {
    const refErrorText = useRef();

    const idErrorContainer = useRef(uuid()).current;

    const [idInterval, setIdInterval] = useState<number>(undefined);

    // const isError = useMemo(
    //   () => !!(errorMessage && errorMessage.length),
    //   [errorMessage]
    // );

    useEffect(() => {
        const timer = setInterval(() => {
            // @ts-ignore
            const height = refErrorText.current?.offsetHeight;
            if (height) {
                document.getElementById(idErrorContainer)?.style &&
                    (document.getElementById(idErrorContainer).style.height =
                        `${height + 5}px`);
            } else {
                document.getElementById(idErrorContainer)?.style &&
                    (document.getElementById(idErrorContainer).style.height =
                        `0px`);
            }
        }, 100);

        setIdInterval(+timer);

        return () => {
            clearInterval(idInterval);
        };
    }, []);

    return (
        <div
            className={`error-input ${className}`}
            id={idErrorContainer}
            style={{
                overflow: 'hidden',
                height: 0
            }}
        >
            <Form.Text
                ref={refErrorText}
                style={{
                    ...styles,
                    fontSize: 12
                    // opacity: isError ? 1 : 0,
                    // transition: 'all 2s ease-in-out'
                }}
                className={'error-input'}
                // className={`${isError ? 'error-input-active' : 'error-input-disable'}`}
            >
                {errorMessage}
            </Form.Text>
        </div>
    );
};

export default Index;
