import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getLocalStorageLang} from "../utils/methods";

// Define a type for the slice state
interface State {
    lang: string;
}

// Define the initial state using that type
const initialState: State = {
    lang: getLocalStorageLang(),
};

export const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        setLang: (state: State, action: PayloadAction<string>) => {
            state.lang = action.payload
        },
    },
});

export const {
    setLang,
} = globalSlice.actions;

export default globalSlice.reducer;
