import EN_flag from '../assets/img/EN_flag.svg';
import Menu2 from '../assets/img/Menu2.svg';
import PL_flag from '../assets/img/PL_flag.svg';
import RU_flag from '../assets/img/RU_flag.svg';
import UA_flag from '../assets/img/UA_flag.svg';
import UZ_flag from '../assets/img/UZ_flag.svg';
import add_circle_outline from '../assets/img/add_circle_outline.svg';
import air from '../assets/img/air.svg';
import air_disabled from '../assets/img/air_disabled.svg';
import app_store from '../assets/img/app_store.svg';
import arrow_up_line from '../assets/img/arrow_up_line.svg';
import arrow_down_line from '../assets/img/arrow_down_line.svg';
import arrow_down_s_line from '../assets/img/arrow_down_s_line.svg';
import arrow_left from '../assets/img/arrow_left.svg';
import arrow_left_black from '../assets/img/arrow_left_black.svg';
import arrow_right from '../assets/img/arrow_right.svg';
import arrow_right_s_line from '../assets/img/arrow_right_s_line.svg';
import buyout from '../assets/img/buyout.svg';
import calculator from '../assets/img/calculator.svg';
import cargo from '../assets/img/cargo.svg';
import cargo_add from '../assets/img/cargo_add.svg';
import china_flag from '../assets/img/china_flag.svg';
import close from '../assets/img/close.svg';
import close_white from '../assets/img/close_white.svg';
import coin_refresh from '../assets/img/coinrefresh.svg';
import copy_all from '../assets/img/copy_all.svg';
import dashboard from '../assets/img/dashboard.svg';
import docs from '../assets/img/docs.svg';
import dollar from '../assets/img/dollar.svg';
import empty_packages_add_package from '../assets/img/empty-packages_add-package.svg';
import empty_packages_buyout from '../assets/img/empty-packages_buyout.svg';
import empty_packages_friend_payment from '../assets/img/empty-packages_friend-payment.svg';
import empty_shipment from '../assets/img/empty_shipment.svg';
import empty_ticket from '../assets/img/empty_ticket.svg';
import empty_tracking from '../assets/img/empty_tracking.svg';
import error from '../assets/img/error.svg';
import eye_close from '../assets/img/eye_close.svg';
import eye_open from '../assets/img/eye_open.svg';
import facebook from '../assets/img/facebook.svg';
import gold_level from '../assets/img/gold_level.svg';
import goods from '../assets/img/goods.svg';
import google from '../assets/img/google.svg';
import google_play from '../assets/img/google_play.svg';
import info_circle from '../assets/img/infocircle.svg';
import loader from '../assets/img/loader.png';
import loader_arrow_blue from '../assets/img/loader_arrow_blue.svg';
import loader_arrow_red from '../assets/img/loader_arrow_red.svg';
import meest from '../assets/img/meest.svg';
import message from '../assets/img/message.svg';
import modal_copy from '../assets/img/modal_copy.svg';
import modal_delete from '../assets/img/modal_delete.svg';
import modal_error from '../assets/img/modal_error.svg';
import modal_send from '../assets/img/modal_send.svg';
import modal_success from '../assets/img/modal_success.svg';
import modal_warning from '../assets/img/modal_warning.svg';
import need_help from '../assets/img/need_help.svg';
import notification from '../assets/img/notification.svg';
import notify_dot from '../assets/img/notify_dot.svg';
import package_add from '../assets/img/package_add.svg';
import package_dash from '../assets/img/package_dash.svg';
import package_icon_dash from '../assets/img/package_icon_dash.svg';
import payment_friend_dash from '../assets/img/payment_friend_dash.svg';
import platinum_level from '../assets/img/platinum_level.svg';
import recipients from '../assets/img/recipients.svg';
import search from '../assets/img/search.svg';
import search_black from '../assets/img/search_black.svg';
import ship from '../assets/img/ship.svg';
import ship_disabled from '../assets/img/ship_disabled.svg';
import shipment from '../assets/img/shipment.svg';
import shipment_add from '../assets/img/shipment_add.svg';
import shipment_dash from '../assets/img/shipment_dash.svg';
import shipment_icon_dash from '../assets/img/shipment_icon_dash.svg';
import shopper_level from '../assets/img/shopper_level.svg';
import standard_level from '../assets/img/standard_level.svg';
import success from '../assets/img/success.svg';
import support from '../assets/img/support.svg';
import user from '../assets/img/user.svg';
import user_white from '../assets/img/user_white.svg';
import global from '../assets/img/global.svg';
import goods_blue from '../assets/img/goods_blue.svg';
import more_vertical from '../assets/img/more_vertical.svg';
import more_line from '../assets/img/more_line.svg';
import coin_refresh_blue from '../assets/img/coin_refresh_blue.svg';
import refresh_blue from '../assets/img/refresh_blue.svg';
import add_round_fill from '../assets/img/add_round_fill.svg';
import danger from '../assets/img/danger.svg';
import danger_disabled from '../assets/img/danger_disabled.svg';
import branded from '../assets/img/branded.svg';
import oversized from '../assets/img/oversized.svg';
import sort from '../assets/img/sort.svg';
import linear from '../assets/img/linear.svg';
import sort_list from '../assets/img/sort_list.svg';
import sort_list_up from '../assets/img/sort_list_up.svg';
import paperclip from '../assets/img/paperclip.svg';
import close_small from '../assets/img/close_small.svg';
import car from '../assets/img/car.svg';
import trash from '../assets/img/trash.svg';
import buyout_blue from '../assets/img/buyout_blue.svg';
import delivery_meest_express from '../assets/img/delivery_meest_express.svg';
import delivery_urkposhta from '../assets/img/delivery_urkposhta.svg';
import delivery_nova_poshta from '../assets/img/delivery_nova_poshta.svg';
import delivery_nova_poshta_disabled from '../assets/img/delivery_nova_poshta_disabled.svg';
import empty_recipients from '../assets/img/empty_recipients.svg';
import add_recipient from '../assets/img/add_recipient.svg';
import wallet from '../assets/img/wallet.svg';
import calendar from '../assets/img/calendar.svg';
import open_link from '../assets/img/open_link.svg';
import exchange from '../assets/img/exchange.svg';
import edit from '../assets/img/edit.svg';
import trash_white_small from '../assets/img/trash_white_small.svg';
import edit_blue from '../assets/img/edit_blue.svg';
import check from '../assets/img/check.svg';
import arrow_right_small from '../assets/img/arrow_right_small.svg';
import shipment_consolidation from '../assets/img/shipment_consolidation.svg';
import shipment_consolidation_disabled from '../assets/img/shipment_consolidation_disabled.svg';
import shipment_one_click from '../assets/img/shipment_one_click.svg';
import shipment_one_click_disabled from '../assets/img/shipment_one_click_disabled.svg';
import create from '../assets/img/create.svg';
import receipt from '../assets/img/receipt.svg';
import filters from '../assets/img/filters.svg';
import warning_blue from '../assets/img/warning_blue.svg';
import minus from '../assets/img/minus.svg';
import plus from '../assets/img/plus.svg';
import playIcon from '../assets/img/playIcon.svg';

interface Icons {
    [key: string]: string;
}

const icons: Icons = {
    EN_flag,
    Menu2,
    PL_flag,
    RU_flag,
    UA_flag,
    UZ_flag,
    add_circle_outline,
    air,
    air_disabled,
    ship,
    ship_disabled,
    car,
    app_store,
    arrow_up_line,
    arrow_down_line,
    arrow_down_s_line,
    arrow_left,
    arrow_left_black,
    arrow_right,
    arrow_right_s_line,
    buyout,
    buyout_blue,
    calculator,
    cargo,
    cargo_add,
    china_flag,
    close,
    close_white,
    coin_refresh,
    copy_all,
    dashboard,
    docs,
    dollar,
    empty_packages_add_package,
    empty_packages_buyout,
    empty_packages_friend_payment,
    empty_shipment,
    empty_ticket,
    empty_tracking,
    error,
    eye_close,
    eye_open,
    facebook,
    gold_level,
    goods,
    google,
    google_play,
    info_circle,
    loader,
    loader_arrow_blue,
    loader_arrow_red,
    meest,
    message,
    modal_copy,
    modal_delete,
    modal_error,
    modal_send,
    modal_success,
    modal_warning,
    need_help,
    notification,
    notify_dot,
    package_add,
    package_dash,
    package_icon_dash,
    payment_friend_dash,
    platinum_level,
    recipients,
    search,
    search_black,
    shipment,
    shipment_add,
    shipment_dash,
    shipment_icon_dash,
    shopper_level,
    standard_level,
    success,
    support,
    user,
    user_white,
    global,
    goods_blue,
    more_vertical,
    more_line,
    coin_refresh_blue,
    add_round_fill,
    danger,
    danger_disabled,
    branded,
    oversized,
    sort,
    linear,
    sort_list,
    sort_list_up,
    paperclip,
    close_small,
    trash,
    delivery_meest_express,
    delivery_urkposhta,
    delivery_nova_poshta,
    delivery_nova_poshta_disabled,
    empty_recipients,
    add_recipient,
    wallet,
    calendar,
    open_link,
    exchange,
    edit,
    trash_white_small,
    edit_blue,
    check,
    arrow_right_small,
    shipment_consolidation,
    shipment_consolidation_disabled,
    shipment_one_click,
    shipment_one_click_disabled,
    create,
    receipt,
    filters,
    warning_blue,
    minus,
    plus,
    playIcon,
    refresh_blue
};

export default icons;
