import { CSSProperties, useEffect, useRef, useState } from 'react';
import { Form } from 'react-bootstrap';
import uuid from 'react-uuid';

const Index = ({
    successMessage,
    className,
    styles
}: {
    styles?: CSSProperties;
    className?: string;
    successMessage: string;
}) => {
    const refSuccessText = useRef();

    const idSuccessContainer = useRef(uuid()).current;

    const [idInterval, setIdInterval] = useState<number>(undefined);

    // const isSuccess = useMemo(
    //   () => !!(successMessage && successMessage.length),
    //   [successMessage]
    // );

    useEffect(() => {
        const timer = setInterval(() => {
            // @ts-ignore
            const height = refSuccessText.current?.offsetHeight;
            if (height) {
                document.getElementById(idSuccessContainer)?.style &&
                    (document.getElementById(idSuccessContainer).style.height =
                        `${height + 5}px`);
            } else {
                document.getElementById(idSuccessContainer)?.style &&
                    (document.getElementById(idSuccessContainer).style.height =
                        `0px`);
            }
        }, 100);

        setIdInterval(+timer);

        return () => {
            clearInterval(idInterval);
        };
    }, []);

    return (
        <div
            className={`success-input ${className}`}
            id={idSuccessContainer}
            style={{
                overflow: 'hidden',
                height: 0
            }}
        >
            <Form.Text
                ref={refSuccessText}
                style={{
                    ...styles,
                    fontSize: 12
                    // opacity: isError ? 1 : 0,
                    // transition: 'all 2s ease-in-out'
                }}
                className={'success-input'}
                // className={`${isError ? 'error-input-active' : 'error-input-disable'}`}
            >
                {successMessage}
            </Form.Text>
        </div>
    );
};

export default Index;
