import * as Yup from "yup";
import {ToastOptions} from "react-toastify/dist/types";
import {getCookie} from "./cookie";
import {scroller} from "react-scroll";
import {DateInterface} from "../interfaces/entity";
import {toast} from "react-toastify";
import {DEFAULT_OPTIONS_TOAST} from "./constants";
import Success from "../components/Icons/Success";
import Error from "../components/Icons/Error";
import {BaseSyntheticEvent} from "react";

export function capitalize(str: string) {
    return str.length ? str.split('')[0].toUpperCase() + str.split('').slice(1).join('') : str;
}

export function copy(value: string, options?: ToastOptions, event?: BaseSyntheticEvent) {
    event && event.stopPropagation()
    const toastFunction = (content: string, options?: ToastOptions) => {
        const opt = {
            ...DEFAULT_OPTIONS_TOAST,
            ...options,
        };
        if (!opt?.icon) {
            if (opt?.type === 'success') {
                return toast.success(content, {...opt, icon: Success()})
            } else if (opt?.type === 'error') {
                return toast.error(content, {...opt, icon: Error()})
            }
        }
        return toast(content, opt)
    };
    navigator.clipboard.writeText(value)
        .then(() => {
            toastFunction('COPIED', {
                type: 'success',
                ...options,
            })
        })
}

export function cleanZeroValue(value: string, isComma = true): string {
    return value.match(/(\.)/) ? value
            .replace(/[0]+$/, '')
            .replace(/[\.]$/, '')
            .replace(/[\.]/, isComma ? ',' : '.')
        : value;
}

export const modifyValueOnlyDigits = (fixedAfterComma?: number) => (value: any): string => {
    const v = value.replaceAll(/[^0-9,\.]|^\.*| +/g, '').replaceAll(/,/g, '.').replace(/\./, ',').replaceAll(/\./g, '').replace(',', '.').toString().split('.')
    value = v[1] === undefined || fixedAfterComma === undefined || v[1].length <= fixedAfterComma ? value.replaceAll(/[^0-9,\.]|^\.*| +/g, '').replaceAll(/,/g, '.').replace(/\./, ',').replaceAll(/\./g, '').replace(',', '.') : v[0] + '.' + v[1].slice(0, fixedAfterComma);
    return value;
}

export function parseNumber(value: string | number): number {
    return isNaN(Number(value)) ? 0 : Number(value);
}

interface ReplacerValuesInterface {
    search: string,
    value: string,
    insensitive?: boolean,
    global?: boolean
}

export function replacer(src: string, values: ReplacerValuesInterface[]): string {

    let newStr = src;

    function makeRegExp(value: ReplacerValuesInterface) {
        let flags = '';
        if (value?.insensitive)
            flags = flags + 'i'
        if (value?.global)
            flags = flags + 'g'

        return new RegExp(`(${value.search})`, flags)

    }

    values.map(v => {
        const regex = makeRegExp(v)
        newStr = newStr.replace(regex, v.value)
    })

    return newStr;
}

export function getUrlToCabinet(redirect_to: string): string {

    const access = getCookie('access-token');
    const refresh = getCookie('refresh-token');

    return process.env.CABINET_URL + `/signin?access=${access}&refresh=${refresh}&redirect_to=${redirect_to}`;
}

export function filledProfile(customer: any) {
    try {
        const validate = Yup.object().shape({
            firstName: Yup
                .string()
                .required(),
            lastName: Yup
                .string()
                .required(),
            middleName: Yup
                .string()
                .required(),
            regionName: Yup
                .string()
                .required(),
            cityName: Yup
                .string()
                .required(),
            streetName: Yup
                .string()
                .required(),
            building: Yup
                .string()
                .required(),
            phone: Yup
                .string()
                .required(),
        }).validateSync(customer);
        return true;
    } catch (error) {
        return false;
    }
}

export function scrollToName(name: string) {
    scroller.scrollTo(name, {
        duration: 200,
        smooth: true,
        offset: -50,
        containerId: 'buyout-list'
    })
}

export function getShiftedDate(startDate: DateInterface, shift: {day?: number, month?: number, year?: number}): DateInterface {
    if (!startDate || !startDate.year || !startDate.month || !startDate.day) {
        return null;
    }
    const date: Date = new Date(startDate.year, startDate.month - 1, startDate.day);

    if (shift.year) {
        date.setFullYear(date.getFullYear() + shift.year);
    }

    if (shift.month) {
        date.setMonth(date.getMonth() + shift.month);
    }

    if (shift.day) {
        date.setDate(date.getDate() + shift.day);
    }

    return {
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
    };
}

export function setLocalStorageLang(lang: string) {
    localStorage.setItem('lang', lang)
}

export function getLocalStorageLang() {
    return localStorage.getItem('lang') ?? 'en'
}
