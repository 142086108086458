import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as Sentry from "@sentry/react";

interface ErrorInterface extends Error {
    config: AxiosRequestConfig;
    response: AxiosResponse;
    message: string;
}

const headersConfig = {
    // 'X-Requested-With': 'XMLHttpRequest',
    // 'Content-Type': 'application/json'
};

function getInstance() {
    return axios.create({
        headers: headersConfig,
        withCredentials: true,
        baseURL: process.env.API_URL,
    });
}

const instance = getInstance();

instance.interceptors.response.use(
    function (response: AxiosResponse) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error: ErrorInterface) {
        const { response } = error;
        const status = response?.status;

        Sentry.captureException(error);

        if (status === 401) {
            window.location.href = '/signin';
        }

        return Promise.reject(error);
    }
);

export default instance;
