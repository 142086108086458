import { BaseSyntheticEvent, useEffect, useRef } from 'react';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import useViewGlobalBackground from '../../hooks/useViewGlobalBackground';
import localStyles from './styles.module.scss';
import Card from '../Card';
import Close from '../Icons/Close';
interface Interface {
    show: boolean;
    containerClass?: string;
    cardClasses?: string;
    headerClasses?: string;
    element: React.ReactNode;
    handleClose: (value: boolean) => void;
    closeOut?: boolean;
    width?: number;
}

const Modal = (props: Interface) => {
    const ref: any = useRef();

    const {
        show,
        width,
        element,
        closeOut: closeOutVar,
        cardClasses,
        headerClasses,
        handleClose
    } = props;

    const showBackGround = useViewGlobalBackground();

    const styleCard = {
        width: width
    };

    useEffect(() => {
        showBackGround(true);

        return () => {
            showBackGround(false);
        };
    }, []);

    useEffect(() => {
        showBackGround(show);
    }, [show]);

    function close() {
        handleClose(false);
    }

    function closeOut(event: BaseSyntheticEvent) {
        if (ref.current && ref.current === event.target && closeOutVar) {
            handleClose(false);
        }
    }

    return show
        ? ReactDOM.createPortal(
              <div
                  ref={ref}
                  className={`${localStyles.container} ${
                      props?.containerClass ?? ''
                  }`}
                  onClick={closeOut}
              >
                  <Card
                      className={`${localStyles.card} ${cardClasses}`}
                      styles={styleCard}
                  >
                      <div className={`${localStyles.header} ${headerClasses}`}>
                          <div
                              className={localStyles.iconClose}
                              onClick={close}
                          >
                              <Close />
                          </div>
                      </div>
                      <div className={localStyles.body}>{element}</div>
                      <div></div>
                  </Card>
              </div>,
              document.getElementById('modals')
          )
        : null;
};

export default Modal;
