import {AxiosResponse} from 'axios';
import axios from '../utils/axios';
import axiosOrigin from 'axios'
import environments from '../utils/environments';
import {LoginDataForm} from "../pages/Login";

export class AuthService {
    private readonly apiUrl: string;

    constructor() {
        this.apiUrl = environments.apiUrl;
    }

    getToken(dataForm: LoginDataForm): Promise<AxiosResponse> {
        const data = new URLSearchParams();
        data.append('username', dataForm.username);
        data.append('password', dataForm.password);

        return axios.post(`${this.apiUrl}/api/v1/auth/getToken`, data, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            }
        });
    }

    isAuthorized(): Promise<AxiosResponse> {
        return axiosOrigin.create({withCredentials: true}).get(`${this.apiUrl}/api/v1/auth/isAuthorized`);
    }

    logout(): Promise<AxiosResponse> {
        return axios.get(`${this.apiUrl}/api/v1/auth/logout`);
    }
}
