import * as ReactDOM from 'react-dom';

import localStyles from './styles.module.scss';

const Index = () => {
    return ReactDOM.createPortal(
        <div
            id={'global-background'}
            data-count={0}
            className={localStyles.container}
        />,
        document.getElementById('modals')
    );
};

export default Index;
