import React, {useCallback, useEffect, useState} from "react";
import localStyles from "./styles.module.scss";
import FilesListItem from "./FilesListItem";
import {useGetLinksQuery, useGetUsersQuery} from "../../../storage/api";
import {socket} from "../../../utils/socket";
import SelectFieldComponent, {
    SelectOptionElementsInterface,
    SelectOptionInterface
} from "../../../components/SelectFieldComponent";

type filterType = {
    userSelected: SelectOptionInterface | SelectOptionElementsInterface | any
}

const FilesList = () => {

    const userAdminIds = [1, 15]

    const userid = localStorage.getItem('userid') ?? 0

    const [list, setList] = useState([])
    const [isConnected, setIsConnected] = useState(socket.connected);
    const [liveScans, setLiveScans] = useState([]);
    const [filter, setFilter] = useState<filterType>({userSelected: null})

    const {data: links, refetch: refetchLinks,} = useGetLinksQuery(filter.userSelected?.value);
    const {data: users, refetch: refetchUsers,} = useGetUsersQuery();

    useEffect(() => {
        function onConnect() {
            setIsConnected(true);
        }

        function onDisconnect() {
            setIsConnected(false);
        }

        function onUpdateLinkEvent(value: any) {
            setList(list.map(item => item.id === value?.id ? value : item))
        }

        function onAddNewLinkEvent(value: any) {
            setList([value, ...list])
        }

        const userid = localStorage.getItem('userid')

        socket.on('connect', onConnect);
        socket.on('disconnect', onDisconnect);
        if (userAdminIds.includes(+userid)) {
            socket.on('update_link', onUpdateLinkEvent);
            socket.on('add_new_link', onAddNewLinkEvent);
        } else {
            socket.on('update_link:' + userid, onUpdateLinkEvent);
            socket.on('add_new_link:' + userid, onAddNewLinkEvent);
        }

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
            if (userAdminIds.includes(+userid)) {
                socket.off('update_link', onUpdateLinkEvent);
                socket.off('add_new_link', onAddNewLinkEvent);
            } else {
                socket.off('update_link:' + userid, onUpdateLinkEvent);
                socket.off('add_new_link:' + userid, onAddNewLinkEvent);
            }
        };
    }, [list]);

    useEffect(() => {
        setList(links ?? [])
    }, [links]);

    useEffect(() => {
        refetchLinks()
    }, [filter]);

    const handleSelectUser = (value: any) => {
        setFilter({...filter, userSelected: value});
    }

    // console.log(filter)

    return (<div className={`container ${localStyles.container}`}>
        {
            userAdminIds.includes(+userid) ?
                <SelectFieldComponent placeholder={'User'}
                                      options={(users ?? []).map(user => ({label: user.username, value: user.id.toString()}))}
                                      selectedValue={filter.userSelected}
                                      handleSelect={handleSelectUser}
                /> : null
        }
        {list.map((item, i) => (
            <div key={item.id}>
                <FilesListItem id={item.id}
                               status={item.status}
                               date={item.created_at}
                               link={item.url}
                               user={item?.user}
                               files={item.files}/>
            </div>
        ))}
    </div>)
}

export default FilesList;
