import ErrorBoundary from '../components/ErrorBoundary';
import {ROUTES} from '../utils/constants';
import NavigateToDashboard from '../components/NavigateToDashboard';
import Dashboard from "../pages/Dashboard";
import Login from "../pages/Login";
import {createBrowserRouter} from "react-router-dom";
import App from "../App";

const routes = [
    {
        path: '/',
        element: App,
        errorElement: ErrorBoundary,
        children: [
            {
                index: true,
                element: NavigateToDashboard,
                errorElement: ErrorBoundary
            },
            {
                path: ROUTES.DASHBOARD,
                element: Dashboard,
                errorElement: ErrorBoundary,
            },
            {
                path: ROUTES.SIGN_IN,
                element: Login,
                errorElement: ErrorBoundary,
            }
        ]
    },
    {
        path: '*',
        element: NavigateToDashboard
    }
];

export default createBrowserRouter(routes);
