import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {RouterProvider} from "react-router-dom";
import routes from "./routes";
import {Provider} from "react-redux";
import store from "./storage";
import * as Sentry from "@sentry/react";
import i18n from 'i18next';
import en from './lang/en.json'
import cn from './lang/cn.json'


Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    // dsn: process.env.SENTRY_DSN,
    dsn: "https://6e75db35f588d60854126ecf6373a1b7@o4507106958376960.ingest.de.sentry.io/4507205000429648",
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

i18n.init({
    resources: {
        en: {translation: en},
        cn: {translation: cn}
    },
    lng: store.getState().global.lang,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
        escapeValue: false // react already safes from xss =>
        // https://www.i18next.com/translation-function/interpolation#unescape
    }
});

ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
).render(
    <Provider
        store={store}
    >
        <RouterProvider router={routes}/>
    </Provider>
);
