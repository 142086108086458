import {
    createRef,
    BaseSyntheticEvent,
    CSSProperties,
    useEffect,
    useState
} from 'react';
import { Form } from 'react-bootstrap';

import localStyles from './styles.module.scss';
import InputErrorBlock from '../InputErrorBlock';
import InputHintBlock from '../InputHintBlock';

import InputSuccessBlock from '../InputSuccessBlock';

export interface InputTextInterface {
    id: string;
    type?: string;
    placeholder?: string;
    classes?: string;
    classesContainer?: string;
    value?: string | number;
    as?: any;
    rows?: number;
    errorMessage?: string;
    successMessage?: string;
    hintMessage?: string;
    loading?: boolean;
    disabled?: boolean;
    hidden?: boolean;
    acceptFiles?: string;
    labelText?: string;
    attr?: object;
    styles?: CSSProperties;
    methodModifyValue?: (value: string) => string;
    name?: string;
    handleKey?: (event: any) => void;
    autocomplete?: boolean | string;
    readOnly?: boolean;
    form?: string;
    disableSuccessBlock?: boolean;
    maxLength?: number;
    handleChange?: handle1 | handle2 | handle3 | handle4;
    handleBlur?: handle1 | handle2 | handle3 | handle4;
    handleFocus?: handle1 | handle2 | handle3 | handle4;
    disableFillColor?: boolean;
}

type handle1 = (value: string) => void;
type handle2 = (value: string, event: any) => void;
type handle3 = (value: string, event: any, id: string) => void;
type handle4 = (value: string, event: any, id: string, ref: any) => void;

const InputText = ({
    id,
    type,
    placeholder,
    classes,
    classesContainer,
    value: v,
    as,
    rows,
    hintMessage,
    loading,
    disabled,
    hidden,
    acceptFiles,
    errorMessage,
    successMessage,
    labelText,
    attr,
    styles,
    methodModifyValue,
    name,
    handleKey,
    autocomplete,
    readOnly,
    form,
    maxLength,
    handleChange,
    handleBlur,
    handleFocus,
    disableFillColor,
    disableSuccessBlock
}: InputTextInterface) => {
    const inputRef: any = createRef();

    const [value, setValue] = useState(v ?? '');
    const [focus, setFocus] = useState<boolean>(false);
    const [input, setInput] = useState<boolean>(false);

    useEffect(() => {
        if (autocomplete !== undefined)
            inputRef.current.setAttribute(
                'autocomplete',
                typeof autocomplete === 'string'
                    ? autocomplete
                    : autocomplete
                      ? 'on'
                      : 'off'
            );
    }, []);

    useEffect(() => {
        setValue(v ?? '');
    }, [v]);

    function onChange(event: any) {
        let str = event.target.value;
        if (str.length > maxLength) return;
        if (methodModifyValue) {
            str = methodModifyValue(str);
        }
        setValue(str);
        handleChange && handleChange(str, event, id, inputRef);
        as === 'textarea' && adjustTextareaHeight();
    }

    function onBlur(event: BaseSyntheticEvent) {
        setFocus(false);
        let str = event.currentTarget.value;
        if (str.length > maxLength) return;
        if (methodModifyValue) {
            str = methodModifyValue(str);
        }
        setValue(str);
        handleBlur && handleBlur(str, event, id, inputRef);
    }

    function onKey(event: any) {
        if (event.key === 'Enter') onChange(event);
        handleKey && handleKey(event);
    }

    function onFocus(event: any) {
        setFocus(true);
        handleFocus &&
            handleFocus(event.currentTarget.value, event, id, inputRef);
    }

    function adjustTextareaHeight() {
        if (inputRef.current) {
            inputRef.current.style.height = 'auto';
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }

    return (
        <Form.Group
            controlId={id}
            className={`my-form position-relative ${classesContainer}`}
        >
            <div
                className={`
                ${
                    errorMessage && errorMessage.length
                        ? `${localStyles.isInvalid} is-invalid `
                        : ' '
                }
                ${
                    !errorMessage && successMessage && successMessage.length
                        ? `${localStyles.isSuccess} is-success `
                        : ' '
                }
            `}
            >
                {labelText && labelText.length ? (
                    <Form.Label
                        className={localStyles.labelPlaceholder}
                        data-focus={focus || !!value}
                    >
                        {labelText}
                    </Form.Label>
                ) : null}
                <Form.Control
                    form={form}
                    style={{
                        ...styles
                    }}
                    {...attr}
                    ref={inputRef}
                    resize={'none'}
                    as={as ? as : 'input'}
                    rows={rows ? rows : 1}
                    className={`form-input ${
                        input ? '' : localStyles.formInputAutoFill
                    } ${localStyles.formInput} ${classes ?? ''}`}
                    type={type ?? 'text'}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDownCapture={onKey}
                    onFocus={onFocus}
                    onInput={() => {
                        setInput(true);
                    }}
                    disabled={disabled || loading}
                    hidden={hidden}
                    accept={acceptFiles}
                    name={name}
                    readOnly={readOnly}
                    data-disable-fill-color={!!disableFillColor}
                />
            </div>
            <InputErrorBlock errorMessage={errorMessage} />
            {!disableSuccessBlock && (
                <InputSuccessBlock
                    successMessage={
                        !errorMessage && successMessage ? successMessage : ''
                    }
                />
            )}
            <InputHintBlock
                hintMessage={
                    !errorMessage && value && value.toString().length
                        ? hintMessage
                        : ''
                }
            />
        </Form.Group>
    );
};

export default InputText;
