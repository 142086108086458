import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import GlobalBackground from "./components/GlobalBackground";
import './styles/app.scss';
import * as Sentry from "@sentry/react";
import i18n from "i18next";
import {useAppSelector} from "./storage/hooks";

const App = () => {

    const lang = useAppSelector(state => state.global.lang)

    return (
        <>
            <Outlet key={lang}/>
            <ToastContainer/>
            <GlobalBackground/>
        </>
    );
}

const NewApp = Sentry.withProfiler(App);

export default <NewApp/>;
