import localStyles from './styles.module.scss';

interface ProgressSpinnerInterface {
    strokewidth: number;
    animationduration: number;
    durationType?: 'ms' | 's';
    styles?: object;
}

const ProgressSpinner = ({
    strokewidth,
    animationduration,
    durationType,
    styles
}: ProgressSpinnerInterface) => {
    return (
        <div
            className={localStyles.spinner}
            style={{
                width: '100%',
                height: '100%',
                ...styles
            }}
        >
            <div
                role="alert"
                aria-busy="true"
                className={localStyles.uiProgressSpinner}
            >
                <svg
                    viewBox="25 25 50 50"
                    className={localStyles.uiProgressSpinnerSvg}
                    style={{
                        animationDuration: `${animationduration}${
                            durationType ?? 's'
                        }`
                    }}
                >
                    <circle
                        cx="50"
                        cy="50"
                        r="20"
                        strokeMiterlimit="10"
                        className={localStyles.uiProgressSpinnerCircle}
                        fill="none"
                        strokeWidth={strokewidth}
                    ></circle>
                </svg>
            </div>
        </div>
    );
};

export default ProgressSpinner;
