import { useRouteError } from 'react-router-dom';

const Index = () => {
    const error = useRouteError() as any;
    return (
        <div>
            <p>{error.message}</p>
            <pre>{error.stack}</pre>
        </div>
    );
};

export default <Index />;
