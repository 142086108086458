import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../utils/constants';

const NavigateToDashboard = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/' + ROUTES.DASHBOARD);
    }, []);

    return <div></div>;
};

export default <NavigateToDashboard />;
