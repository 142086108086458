import { BaseSyntheticEvent, CSSProperties, useRef } from 'react';

import localStyles from './styles.module.scss';
import useOutsideAlerter from '../../hooks/useOutsideAlerter';

interface Interface {
    id?: string;
    children?: any;
    styles?: CSSProperties;
    className?: string;
    handleOutside?: () => void;
    boxShadow?: boolean;
    backgroundColor?: 'light-blue' | 'light-grey';
    dataShow?: boolean;
    onClick?: (event?: BaseSyntheticEvent) => void;
    onKey?: (event?: BaseSyntheticEvent) => void;
}

const Card = ({
    id,
    children,
    styles,
    className,
    handleOutside,
    boxShadow,
    backgroundColor,
    dataShow,
    onClick,
    onKey: handleKey,
    ...props
}: Interface) => {
    const ref = useRef();

    useOutsideAlerter(ref, () => {
        handleOutside && handleOutside();
    });

    function onKey(event: any) {
        handleKey && handleKey(event);
    }

    return (
        <div
            id={id}
            ref={ref}
            data-show={!!dataShow}
            className={`${localStyles.container} ${className}`}
            style={{
                ...styles
            }}
            data-box-shadow={boxShadow || boxShadow === undefined}
            data-background={backgroundColor}
            {...props}
            onClick={onClick}
            onKeyDownCapture={onKey}
            data-clickable={!!onClick}
        >
            {children}
        </div>
    );
};

export default Card;
