import React, {BaseSyntheticEvent, MutableRefObject, useEffect, useMemo, useRef, useState} from 'react';

import localStyles from './styles.module.scss'
import useOutsideAlerter from "../../hooks/useOutsideAlerter";
import icons from "../../utils/icons";
import translate from "../../utils/translate";
import InputErrorBlock from "../Inputs/InputErrorBlock";
import * as ReactDOM from "react-dom";
import {SelectOptionWithOriginalObjectInterface} from "../../utils/parsers";

export interface SelectOptionInterface {
    value: string;
    label: string;
}

export interface SelectOptionElementsInterface {
    value: string;
    element: JSX.Element;
    elementSelect?: JSX.Element;
}

interface Interface {
    id?: string,
    name?: string,
    className?: string,
    classNameDropDownIndicator?: string,
    classNameValueContainer?: string,
    classNameSelectMenu?: string,
    placeholder?: string,
    options: SelectOptionInterface[] | SelectOptionElementsInterface[],
    noOptionsText?: string,
    isLoading?: boolean,
    selectedValue?: SelectOptionInterface | SelectOptionElementsInterface,
    handleSearch?: (value: string) => void,
    handleSelect?: <T>(value: SelectOptionInterface | SelectOptionElementsInterface | SelectOptionWithOriginalObjectInterface<T> | T) => void,
    isDynamicSearch?: boolean,
    disabled?: boolean,
    errorMessage?: string,
    getRefContainerCallback?: (ref: MutableRefObject<any>) => void
    onlyArrowDropDownIndicator?: boolean,
    deniedSelect?: boolean,
    variant?: 'profile' | 'default'
}

const SelectFieldComponent = ({
                                  id,
                                  name,
                                  className,
                                  classNameDropDownIndicator = '',
                                  classNameValueContainer = '',
                                  classNameSelectMenu = '',
                                  placeholder,
                                  options,
                                  noOptionsText,
                                  isLoading,
                                  selectedValue,
                                  handleSearch,
                                  handleSelect,
                                  isDynamicSearch,
                                  errorMessage,
                                  disabled,
                                  getRefContainerCallback = () => {},
                                  onlyArrowDropDownIndicator,
                                  deniedSelect,
                                  variant = 'default',
                                  ...props
                              }: Interface) => {

    const [focus, setFocus] = useState<boolean>(false)
    const [searchValue, setSearchValue] = useState<string>('')
    const [selected, setSelected] = useState<SelectOptionInterface | SelectOptionElementsInterface>(null)
    const [isSearched, setIsSearched] = useState<boolean>(false)
    const [componentOptions, setComponentOptions] = useState<any[]>([])
    const [coords, setCoords] = useState<DOMRect>(null)
    const [scrollBodyValue, setScrollBodyValue] = useState<number>(0)

    const refMenu = useRef();
    const refInput = useRef();
    const refContainer = useRef();

    useEffect(() => {
        getRefContainerCallback(refContainer)
    }, [refContainer]);

    useOutsideAlerter(refMenu, (event: MouseEvent) => {
        (event.target as HTMLImageElement).id !== 'select-drop-down' && setFocus(false)
    })

    const filteredOptions: SelectOptionInterface[] = useMemo(() => {

        if (!searchValue) return componentOptions;

        const search = searchValue.split('')
            .map(char => ['(', ')', '[', ']', '/', '\\', '|', '.', ',', '?', '^'].includes(char) ? `\\${char}` : char)
            .join('')

        const regex = new RegExp(`(${search})`, 'i')

        return componentOptions.filter(v => v.label.match(regex) && (!selected || searchValue.length))

    }, [searchValue, componentOptions, selected])

    useEffect(() => {
        setInterval(() => {
            const div = refContainer.current as HTMLDivElement;
            setCoords(div?.getBoundingClientRect() ?? null)
            setScrollBodyValue(document.body.scrollTop)
        }, 100)
    }, [])

    useEffect(() => {
        setComponentOptions(options)
    }, [options])

    useEffect(() => {
        setFocus(false)
    }, [selected])

    useEffect(() => {
        if (selectedValue !== undefined && selectedValue !== selected) {
            setSelected(selectedValue)
        }
    }, [selectedValue])

    useEffect(() => {
        if (!focus) {
            setTimeout(() => {
                setSearchValue('')
                // isDynamicSearch && setComponentOptions([])
            }, 500)
        }
    }, [focus])

    useEffect(() => {
        isSearched && handleSearch && handleSearch(searchValue)
    }, [searchValue])

    function setFocusInput() {
        if (refInput.current)
            (refInput.current as HTMLInputElement).focus()
        else {
            setFocus(!focus)
        }
    }

    function instanceOfSelectOptionsElements(object: any): object is SelectOptionElementsInterface {
        return 'value' in object && 'element' in object;
    }

    // console.log(options)
    return (
        <div ref={refContainer}
             data-name={name ?? ''}
             className={`${localStyles.container} ${className ?? ''} my-form`}
             data-disabled={disabled}
             data-variant={variant}
             data-focus={focus}
             data-is-set-value={!!searchValue || !!selected}
             id={id}
        >
            <div className={localStyles.valueWrapper}
                 data-error={!!errorMessage}
                 data-disabled={disabled}
            >
                <div className={localStyles.placeholder} onClick={() => {
                    !deniedSelect && setFocusInput()
                }}>
                    {placeholder}
                </div>
                {
                    (options && options.length && instanceOfSelectOptionsElements(options.slice(0, 1)[0]))
                        ?
                        <div className={classNameValueContainer}>
                            {(selected as SelectOptionElementsInterface)?.elementSelect ?? (selected as SelectOptionElementsInterface)?.element}
                        </div>
                        :
                        <input
                            disabled={disabled}
                            data-variant={variant}
                            ref={refInput}
                            type="text"
                            value={((selected as SelectOptionInterface)?.label && !focus ? (selected as SelectOptionInterface)?.label : '') || searchValue}
                            className={`${localStyles.valueContainer} ${classNameValueContainer}`}
                            onChange={(event: BaseSyntheticEvent) => {
                                if (!isSearched)
                                    setIsSearched(true);
                                setSearchValue(event.currentTarget.value)
                            }}
                            onFocus={() => {
                                !deniedSelect && setFocus(true)
                            }}
                        />
                }
                <div className={`${localStyles.dropDownIndicator} ${classNameDropDownIndicator}`}
                     data-disabled={disabled}
                     onClick={() => {
                         if (selected && !focus && !disabled && !deniedSelect) {
                             if (onlyArrowDropDownIndicator) {
                                 setFocus(true)
                             } else {
                                 setSelected(null)
                                 setComponentOptions([])
                                 handleSelect && handleSelect(null)
                             }
                         } else {
                             !deniedSelect && setFocusInput()
                         }
                     }}
                >
                    {
                        !disabled && !deniedSelect ?
                            selected && !focus && !onlyArrowDropDownIndicator
                                ? <img id={'select-drop-down'} data-focus={focus} src={icons.close} alt="close"/>
                                : <img id={'select-drop-down'} data-focus={focus} src={icons.arrow_down_s_line} alt="arrow-down"/>
                            : null
                    }
                </div>
            </div>
            <InputErrorBlock errorMessage={errorMessage} className={localStyles.errorContainer}/>
            {
                ReactDOM.createPortal(
                    <div className={`${localStyles.selectMenu} ${classNameSelectMenu}`}
                         data-focus={focus}
                         ref={refMenu}
                         style={{
                             top: (coords?.top ?? 0) + 52 + scrollBodyValue,
                             left: coords?.left,
                             width: coords?.width
                         }}
                    >
                        <div className={`${localStyles.menuContainer} custom-scroll`}>
                            {
                                filteredOptions.map((v, i) => {

                                    return (
                                        <div key={i}
                                             data-active={v.value === selected?.value}
                                             className={localStyles.selectItem}
                                             onClick={() => {
                                                 setSelected(v)
                                                 handleSelect && handleSelect(v)
                                             }}
                                        >{instanceOfSelectOptionsElements(v) ? v.element : v.label}</div>
                                    )
                                })
                            }
                            {
                                !filteredOptions.length && (componentOptions.length || isSearched) && searchValue.length && !isLoading ?
                                    <div className={localStyles.emptyItem}
                                    >{translate('OUR.EMTPY_FILTER')}</div>
                                    : null
                            }
                            {
                                noOptionsText && !componentOptions.length && !searchValue.length && !isLoading ?
                                    <div className={localStyles.emptyItem}
                                    >{noOptionsText}</div>
                                    : null
                            }
                        </div>
                    </div>,
                    document.getElementById('select-portal')
                )
            }
        </div>
    );
};

export default SelectFieldComponent;
