import { useEffect } from 'react';
import * as React from 'react';

type HandleFunction = (event: Event, ref: React.RefObject<any>) => void;

const UseOutsideAlerter = (
    ref: React.RefObject<any>,
    handle: HandleFunction,
    triggers?: any[],
    anyRefs?: React.RefObject<any>[]
) => {
    useEffect(() => {
        function handleClickOutside(event: Event) {
            const refs = [...(anyRefs ?? []), ref];
            let isOutside = true;
            refs.forEach((ref) => {
                if (ref.current && ref.current.contains(event.target)) {
                    isOutside = false;
                }
            });
            if (isOutside) handle(event, ref);
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref, triggers]);
};

export default UseOutsideAlerter;
