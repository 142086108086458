import {configureStore} from '@reduxjs/toolkit'

import {api} from './api'

import global from "./global";
import {setupListeners} from "@reduxjs/toolkit/query";

/**
 * Logs all actions and states after they are dispatched.
 */
const logger = (store: any) => (next: any) => (action: any) => {
    return next(action)
}

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        global: global,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(api.middleware),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export default store;

setupListeners(store.dispatch)
