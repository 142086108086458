import {toast} from 'react-toastify';
import {ToastOptions} from "react-toastify/dist/types";
import {DEFAULT_OPTIONS_TOAST} from "../utils/constants";
import Success from "../components/Icons/Success";
import Error from "../components/Icons/Error";

const useToastifyHook = () => {
    return (content: string, options: ToastOptions) => {

        const opt = {
            ...DEFAULT_OPTIONS_TOAST,
            ...options,
        };

        if (!options.icon) {
            if (options.type === 'success') {
                return toast.success(content, {...opt, icon: Success()})
            } else if (options.type === 'error') {
                return toast.error(content, {...opt, icon: Error()})
            }
        }
        return toast(content, opt)
    }
};

export default useToastifyHook;
