import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import axios from "../utils/axios";
import environments from "../utils/environments";
import {CoreService} from "../services/core.service";

export interface APILinksItemData {
    "id": number;
    "user_id": number;
    "url": string;
    "status_id": number;
    "is_upload_to_bb": number;
    "created_at": string;
    "status_name": string;
    "files": any[]
}


export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({baseUrl: "http://localhost:7070/api/"}),
    endpoints: (builder) => ({
        getLinks: builder.query<APILinksItemData[], string|void>({
            queryFn: async (userId: string) => {
                console.log(userId)
                try {
                    const response = await (new CoreService()).getLinks(userId)
                    return {data: await response.data}
                } catch (e) {
                    return {error: e.message}
                }
            },
            keepUnusedDataFor: 0,
        }),
        getUsers: builder.query<{id: number, username: string}[], void>({
            queryFn: async () => {
                try {
                    const response = await (new CoreService()).getUsers()
                    return {data: await response.data}
                } catch (e) {
                    return {error: e.message}
                }
            },
            keepUnusedDataFor: 0,
        })
    }),
});

export const {
    useGetLinksQuery,
    useGetUsersQuery,
} = api;
