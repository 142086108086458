import React, {useEffect, useState} from "react";
import localStyles from "./styles.module.scss"
import {Form, Formik} from 'formik';
import * as Yup from 'yup';
import Button from "../../components/Button";
import InputText from "../../components/Inputs/InputText";
import InputPassword from "../../components/Inputs/InputPassword";
import {AxiosError, AxiosResponse} from "axios";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../utils/constants";
import {AuthService} from "../../services/auth.service";

export interface LoginDataForm {
    username: string;
    password: string;
}

const Login = () => {

    const navigate = useNavigate();
    const [serverError, setServerError] = useState("");
    const authService = new AuthService();

    useEffect(() => {
        authService.isAuthorized()
            .then((response: AxiosResponse) => {
                if (response.status === 200) {
                    navigate("/" + ROUTES.DASHBOARD);
                }
            });
    }, []);

    return (
        <div className={localStyles.container}>
            <Formik
                initialValues={{
                    username: '',
                    password: ''
                }}
                validationSchema={Yup.object().shape({
                    username: Yup.string().required('Login is required'),
                    password: Yup.string().required('Password is required')
                })}
                onSubmit={(data, formikHelpers) => {
                    setServerError("");
                    const {username, password} = data;

                    authService.getToken({username, password})
                        .then((response: AxiosResponse) => {
                            setServerError("");
                            const username = response.data?.username;
                            const userid = response.data?.id;
                            localStorage.setItem("username", username ? username : "No name");
                            localStorage.setItem("userid", userid ? userid : "No id");
                            navigate("/" + ROUTES.DASHBOARD);
                        })
                        .catch((error: AxiosError) => {
                            const message: string = error?.response?.statusText ? error?.response?.statusText : error?.message;
                            // console.error(message);
                            setServerError(message);
                            formikHelpers.setSubmitting(false);
                        });
                }}
            >
                {({
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      setFieldTouched,
                      isSubmitting,
                  }) => {
                    return (
                        <Form onSubmit={handleSubmit}
                              noValidate
                              className={'auth-form hide-scrollbar-mobile animate-page'}
                        >
                            <p className={localStyles.title}>
                                M<span>e</span>estShareFiles
                            </p>
                            <InputText
                                id={'username'}
                                type={'text'}
                                labelText={'Username'}
                                classesContainer={'mb-3'}
                                errorMessage={touched.username ? errors.username : ''}
                                disableFillColor
                                handleChange={(username: string, event: any) => {
                                    event.target.value = username
                                        .toString()
                                        .trim();
                                    handleChange(event);
                                    setFieldTouched('username', true, false);
                                }}
                                handleBlur={(username: string, event: any) => {
                                    event.target.value = username
                                        .toString()
                                        .trim();
                                    if (username.length) handleBlur(event);
                                }}
                            />
                            <InputPassword
                                id={'password'}
                                labelText={"Password"}
                                classesContainer={'mb-3'}
                                errorMessage={
                                    touched.password ? errors.password : ''
                                }
                                disableFillColor
                                handleChange={(
                                    password: string,
                                    event: any
                                ) => {
                                    event.target.value = password
                                        .toString()
                                        .trim();
                                    handleChange(event);
                                    setFieldTouched('password', true, false);
                                }}
                                handleBlur={(password: string, event: any) => {
                                    event.target.value = password
                                        .toString()
                                        .trim();
                                    if (password.length) handleBlur(event);
                                }}
                            />
                            <p data-if={!!serverError} className={localStyles.errorField}>{serverError}</p>
                            <Button
                                disabled={isSubmitting}
                                id={'submit-login'}
                                size={'large'}
                                background={'bg-primary'}
                                text={"Sign-in"}
                                type={'submit'}
                                classes={'text-capitalize'}
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default <Login/>;
