import {createRef, BaseSyntheticEvent, CSSProperties, ReactNode} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button as ButtonBootstrap } from 'react-bootstrap';
import localStyles from './styles.module.scss';

export interface handleButton {
    (event: BaseSyntheticEvent, name: string, ref: any): void;
}

export interface ButtonInterface {
    id: string;
    click?: handleButton;
    classes?: string;
    type?: 'button' | 'submit' | 'reset';
    text?: string;
    href?: string;
    styles?: CSSProperties;
    disabled?: boolean;
    appendBefore?: ReactNode;
    appendAfter?: ReactNode;
    size: 'large' | 'premiddle' | 'middle' | 'small';
    background:
        | 'bg-primary'
        | 'bg-danger'
        | 'bg-outline'
        | 'bg-outline-grey'
        | 'bg-green';
    uppercase?: boolean;
    activeClass?: string;
    form?: string;
    containerClass?: string;
}

const Button = ({
    id,
    click,
    classes,
    type,
    text,
    href,
    styles,
    disabled,
    appendBefore,
    appendAfter,
    background,
    size,
    uppercase,
    activeClass,
    form,
    containerClass,
    ...props
}: ButtonInterface) => {
    const ref: any = createRef();

    const navigate = useNavigate();

    function onClick(event: BaseSyntheticEvent) {
        if (click) {
            click(event, id, ref);
        }
        if (href) {
            navigate(href);
        }
    }

    return (
        <div
            className={
                `${containerClass} ` +
                (id === 'edit-personal-data'
                    ? localStyles.buttonEditContainer
                    : '')
            }
            {...props}
        >
            <ButtonBootstrap
                style={{
                    minWidth: 100,
                    width: '100%',
                    ...styles
                }}
                id={id}
                ref={ref}
                onClick={onClick}
                type={type ?? 'button'}
                className={`
                ${localStyles.button} 
                ${classes ? classes : ''} 
                ${uppercase ? 'text-uppercase' : ''} 
                ${activeClass}
                `}
                data-background={background}
                data-size={size}
                disabled={disabled}
                form={form}
            >
                {appendBefore}
                <span>{text ?? ''}</span>
                {appendAfter}
            </ButtonBootstrap>
        </div>
    );
};

export default Button;
